
import { defineComponent, ref, watch } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";

export default defineComponent({
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const submitButton = ref<HTMLButtonElement | null>(null);

    const showPassword = ref(false);

    //Create form validation object
    const login = Yup.object().shape({
      login: Yup.string().required().label("Username"),
      password: Yup.string().min(3).required().label("Password"),
    });

    //Form submit function
    const onSubmitLogin = async (values) => {

        try {
          // Clear existing errors
          //await store.dispatch(Actions.LOGOUT);

          if (submitButton.value) { 
            submitButton.value.disabled = true;
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }

          // Send login request
          await store.dispatch(Actions.LOGIN, values)
          .then((response) => {
            Swal.fire({
              text: response.messages,
              icon: "success",
              buttonsStyling: false,
              showConfirmButton: false,
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
              timer: 1000,
            }).then(function () {
              // Go to page after successfully login
              router.push({ name: "dashboard" });
            });

          })
          .catch((error) => {
            Swal.fire({
              title: 'Error',
              text: error.data.messages,
              icon: "error",
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
              timer: 3000
            });
          })

      } catch(e) {
        Swal.fire({
          title: 'Error',
          text: 'Request Error',
          icon: "error",
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
          timer: 3000
        });
      }

      if(submitButton.value) { 
        submitButton.value.removeAttribute("data-kt-indicator");
        submitButton.value.disabled = false;
      }

    };

    watch(() => showPassword.value, (val) => {
      if(val) {
        //
      }
    })

    return {
      showPassword,
      onSubmitLogin,
      login,
      submitButton,
    };
  },
});
